import React from "react";
import NavbarComponent from "../components/NavbarComponent";
import CountryForm from "../components/BannerForm";
import { Button, Label, Select, Datepicker } from "flowbite-react";
import { FaCircleUser } from "react-icons/fa6";
import {
  MdLocalActivity,
  MdMarkEmailUnread,
  MdLock,
  MdDescription,
} from "react-icons/md";
import { BsFillCalendar2EventFill } from "react-icons/bs";
import { IoIosBusiness } from "react-icons/io";
import { BiSolidCategory } from "react-icons/bi";
import { TbWorld, TbBuildingEstate } from "react-icons/tb";
import { PiCityFill } from "react-icons/pi";
import { SlCalender } from "react-icons/sl";
import { GiAbstract016 } from "react-icons/gi";
import FooterComponent from "../components/FooterComponent";
import { getCountries, getTopics, getSubtopics } from "../services/api";
import { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import useAddEventForm from "../hooks/useAddEventForm";
import FieldWithIcon from "../components/FieldWithIcon";
import { SiEventbrite } from "react-icons/si";
import { IoIosContact } from "react-icons/io";
import { BsBrowserChrome } from "react-icons/bs";

function AddEvent() {
  // const GOOGLE_CAPTCHA_KEY = process.env.GOOGLE_CAPTCHA_KEY;

  const { formData, isSubmitting, handleSubmit, setFormData, recaptchaRef,
    setVerified, } =
    useAddEventForm();
  
  const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  
  const [event_countries, setCountries] = useState([]);
  const [event_topics, setTopics] = useState([]);
  const [event_subtopics, setSubtopics] = useState([]);
  const [keywordsubtopics, keywordsetSubtopics] = useState([]);
  // const [verified, setVerified] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedSubtopic, setSelectedSubtopic] = useState("");

  const handleRecaptchaChange = (token) => {
    if (token) {
      setVerified(true);
    } else {
      setVerified(false);
    }
  };
  // Fetch countries when component mounts
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getCountries();
        setCountries(response.data); // Assuming response.data contains the array of countries
      } catch (error) {
        console.error("Error fetching countries", error);
      }
    };

    fetchCountries(); // Call the async function
  }, []);

  // Fetch topics when component mounts
  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const response = await getTopics();
        setTopics(response.data); // Assuming topics come as an array
      } catch (error) {
        console.error("Error fetching topics", error);
      }
    };

    fetchTopics();
  }, []);

  // Inside BannerForm.jsx
  useEffect(() => {
    if (selectedTopic) {
      const fetchSubtopics = async () => {
        try {
          const response = await getSubtopics(selectedTopic); // Pass selectedTopic to fetch subtopics
          setSubtopics(response.data); // Assuming subtopics come as an array
        } catch (error) {
          console.error("Error fetching subtopics", error);
        }
      };
      fetchSubtopics();
    } else {
      setSubtopics([]); // Clear subtopics if no topic is selected
    }
  }, [selectedTopic]);

  useEffect(() => {
    // Fetch topics using the getTopics function
    getTopics()
      .then((response) => {
        setTopics(response.data);

        // Fetch subtopics for each category using getSubtopics function
        response.data.forEach((category) => {
          getSubtopics(category.id)
            .then((res) => {
              keywordsetSubtopics((prev) => ({
                ...prev,
                [category.id]: res.data.filter(
                  (subtopic) => subtopic.status === "SHOW"
                ),
              }));
            })
            .catch((err) =>
              console.error(
                `Error fetching subtopics for category ${category.id}:`,
                err
              )
            );
        });
      })
      .catch((err) => console.error("Error fetching topics:", err));
  }, []);

  const handleEventCountryChange = (e) => {
    const value = e.target.value;
    setSelectedCountry(value);
    setFormData((prev) => ({ ...prev, eventCountry: value }));
  };
  const handleTopicChange = (e) => {
    const value = e.target.value;
    setSelectedTopic(value); // Update selected topic
    setFormData((prev) => ({ ...prev, eventCategory: value }));
  };

  const handleSubtopicChange = (e) => {
    const value = e.target.value;
    setSelectedSubtopic(e.target.value); // Update selected subtopic
    setFormData((prev) => ({ ...prev, eventTopic: value }));
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    let updatedKeywords = [];

    // Get the current list of keywords from the FormData (assuming it's an object)
    const currentKeywords = formData.eventKeywords || ""; // Default to an empty string if undefined

    // Ensure currentKeywords is treated as a string
    const keywordsArray =
      typeof currentKeywords === "string" ? currentKeywords.split("#") : [];

    if (checked) {
      // Add keyword if checked
      updatedKeywords = [...keywordsArray, value];
    } else {
      // Remove keyword if unchecked
      updatedKeywords = keywordsArray.filter((keyword) => keyword !== value);
    }

    // Update the FormData object
    const updatedFormData = {
      ...formData,
      eventKeywords: updatedKeywords.join("#"), // Convert back to a string
    };

    setFormData(updatedFormData); // Trigger state update for React
  };

  return (
    <div>
      <NavbarComponent />
      <CountryForm />

      {/* Full-Width Gradient for Add New Event */}
      <div className="w-full bg-gradient-to-r from-cyan-500 via-cyan-600 to-cyan-700 text-center py-8">
        <h1 className="text-4xl font-serif text-white">Add New Event</h1>
      </div>

      <div className="container mx-auto md:p-8 p-4 md:px-20 py-8">
        <form
          onSubmit={handleSubmit}
          className="grid md:grid-cols-2 grid-cols-1 gap-8"
        >
          {/* Left Column */}
          <div>
            <div className="bg-white rounded-2xl shadow-lg overflow-hidden p-6">
              <div className="grid grid-cols-1 gap-6">
                <h3 className="font-serif text-2xl text-blue-900 pb-4 border-b-2 border-blue-900">
                  Event Organizer Details
                </h3>
                <div className="flex flex-col space-y-4">
                  {/* <FieldWithIcon icon={FaCircleUser} id="organizerName" label="Organizer Name" required={true} /> */}

                  <FieldWithIcon
                    icon={FaCircleUser}
                    id="organizerName"
                    label="Organizer Name"
                    required={true}
                    value={formData.organizerName}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        organizerName: e.target.value,
                      }))
                    }
                  />
                  <FieldWithIcon
                    icon={MdLocalActivity}
                    id="organizationName"
                    label="Organization Name"
                    required={true}
                    value={formData.organizationName}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        organizationName: e.target.value,
                      }))
                    }
                  />
                  <FieldWithIcon
                    icon={MdMarkEmailUnread}
                    id="organizerEmail"
                    type="email"
                    label="Organizer Email"
                    placeholder="name@example.com"
                    required={true}
                    value={formData.organizerEmail}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        organizerEmail: e.target.value,
                      }))
                    }
                  />
                  <FieldWithIcon
                    icon={MdLock}
                    id="password"
                    type="password"
                    label="Password"
                    placeholder="Enter your password"
                    required={true}
                    value={formData.password}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        password: e.target.value,
                      }))
                    }
                  />
                  <FieldWithIcon
                    icon={MdLock}
                    id="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    placeholder="Confirm your password"
                    required={true}
                    value={formData.confirmPassword}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        confirmPassword: e.target.value,
                      }))
                    }
                  />
                </div>

                <h3 className="font-serif text-2xl text-blue-900 pb-4 border-b-2 border-blue-900">
                  Event Details
                </h3>

                <div className="flex flex-col space-y-4">
                  <FieldWithIcon
                    icon={BsFillCalendar2EventFill}
                    id="eventName"
                    type="eventName"
                    label="Event Name"
                    required={true}
                    value={formData.eventName}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        eventName: e.target.value,
                      }))
                    }
                  />

                  <div className="flex items-center gap-3">
                    <IoIosBusiness className="text-xl text-gray-600" />
                    <Label
                      htmlFor="eventType"
                      className="text-lg text-gray-800"
                    >
                      Event Type
                    </Label>
                    <span className="text-orange-400">*</span>
                  </div>
                  <Select
                    id="eventType"
                    name="eventType"
                    className="rounded-xl shadow-lg"
                    required
                    value={formData.eventType}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        eventType: e.target.value,
                      }))
                    }
                  >
                    <option>Conference</option>
                    <option>Seminar</option>
                    <option>Workshop</option>
                    <option>Exhibition</option>
                  </Select>

                  <div className="flex items-center gap-3">
                    <BiSolidCategory className="text-xl text-gray-600" />
                    <Label
                      htmlFor="eventCategory"
                      className="text-lg text-gray-800"
                    >
                      Event Category
                    </Label>
                    <span className="text-orange-400">*</span>
                  </div>
                  <Select
                    id="eventCategory"
                    value={selectedTopic}
                    onChange={handleTopicChange}
                    required
                  >
                    <option value="" disabled>
                      Select a topic
                    </option>
                    {event_topics.map((event_topic, index) => (
                      <option
                        key={`${event_topic.id}-${index}`}
                        value={event_topic.id}
                      >
                        {event_topic.category}
                      </option>
                    ))}
                  </Select>

                  <Select
                    id="eventTopic"
                    value={selectedSubtopic}
                    onChange={handleSubtopicChange}
                    required
                    disabled={!event_subtopics.length}
                  >
                    <option value="" disabled>
                      Select a subtopic
                    </option>
                    {event_subtopics.map((event_subtopic, index) => (
                      <option
                        key={`${event_subtopic.id}-${index}`}
                        value={event_subtopic.topic}
                      >
                        {event_subtopic.topic}
                      </option>
                    ))}
                  </Select>

                  <div className="flex items-center gap-3">
                    <TbWorld className="text-xl text-gray-600" />
                    <Label
                      htmlFor="eventCountry"
                      className="text-lg text-gray-800"
                    >
                      Select Country
                    </Label>
                    <span className="text-orange-400">*</span>
                  </div>
                  <Select
                    id="eventCountry"
                    value={selectedCountry}
                    onChange={handleEventCountryChange}
                    className="rounded-xl shadow-lg"
                    required
                  >
                    <option value="" disabled>
                      Select a country
                    </option>
                    {event_countries.map((event_country) => (
                      <option
                        key={`${event_country.id}-${event_country.country}`}
                        value={event_country.country}
                      >
                        {event_country.country}
                      </option>
                    ))}
                  </Select>
                  <FieldWithIcon
                    icon={TbBuildingEstate}
                    id="eventState"
                    label="State"
                    required={true}
                    value={formData.eventState}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        eventState: e.target.value,
                      }))
                    }
                  />
                  <FieldWithIcon
                    icon={PiCityFill}
                    id="eventCity"
                    label="City"
                    required={true}
                    value={formData.eventCity}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        eventCity: e.target.value,
                      }))
                    }
                  />
                  <FieldWithIcon
                    icon={SiEventbrite}
                    id="eventOrgSociety"
                    label="Organizing Society"
                    required={true}
                    value={formData.eventOrgSociety}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        eventOrgSociety: e.target.value,
                      }))
                    }
                  />
                  <FieldWithIcon
                    icon={IoIosContact}
                    id="contactPerson"
                    label="Contact Person For Event"
                    required={true}
                    value={formData.contactPerson}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        contactPerson: e.target.value,
                      }))
                    }
                  />
                  <FieldWithIcon
                    icon={MdMarkEmailUnread}
                    id="eventEnqueryEmail"
                    label="Event Enquiries Email Address"
                    required={true}
                    value={formData.eventEnqueryEmail}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        eventEnqueryEmail: e.target.value,
                      }))
                    }
                  />
                  <FieldWithIcon
                    icon={BsBrowserChrome}
                    id="website"
                    label="Website Address"
                    required={true}
                    value={formData.website}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        website: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Right Column */}
          <div>
            <div className="bg-white rounded-2xl shadow-lg p-6 space-y-4">
              <h3 className="font-serif text-2xl text-blue-900 pb-4 border-b-2 border-blue-900">
                Event Timings
              </h3>
              <div className="flex items-center gap-3">
                <SlCalender className="text-xl text-gray-600" />
                <Label
                  htmlFor="eventStartDate"
                  className="text-lg text-gray-800"
                >
                  Event Start Date
                </Label>
                <span className="text-orange-400">*</span>
              </div>
              <Datepicker
                id="eventStartDate"
                selected={formData.eventStartDate}
                onChange={(date) =>
                  setFormData((prev) => ({ ...prev, eventStartDate: date }))
                }
                className="rounded-xl shadow-lg"
                required
              />

              <div className="flex items-center gap-3">
                <SlCalender className="text-xl text-gray-600" />
                <Label htmlFor="eventEndDate" className="text-lg text-gray-800">
                  Last Day of Event
                </Label>
                <span className="text-orange-400">*</span>
              </div>
              <Datepicker
                id="eventEndDate"
                selected={formData.eventEndDate}
                onChange={(date) =>
                  setFormData((prev) => ({ ...prev, eventEndDate: date }))
                }
                className="rounded-xl shadow-lg"
                required
              />

              <div className="flex items-center gap-3">
                <GiAbstract016 className="text-xl text-gray-600" />
                <Label
                  htmlFor="abstractDeadline"
                  className="text-lg text-gray-800"
                >
                  Deadline for Abstracts
                </Label>
                <span className="text-orange-400">*</span>
              </div>
              <Datepicker
                id="abstractDeadline"
                selected={formData.abstractDeadline}
                onChange={(date) =>
                  setFormData((prev) => ({ ...prev, abstractDeadline: date }))
                }
                className="rounded-xl shadow-lg"
                required
              />

              <div className="flex items-center gap-3">
                <MdDescription className="text-xl text-gray-600" />
                <Label
                  htmlFor="eventDescription"
                  className="text-lg text-gray-800"
                >
                  Short Description
                </Label>
                <span className="text-orange-400">*</span>
              </div>
              <textarea
                id="eventDescription"
                rows="4"
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-xl border border-gray-300 focus:ring-blue-500 focus:border-blue-500 shadow-lg"
                placeholder="Briefly describe the event..."
                required
                value={formData.eventDescription}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    eventDescription: e.target.value,
                  }))
                }
              ></textarea>

              {/* Event Keywords Section */}
              <div className="h-[570px] overflow-y-auto border p-4">
                <h3 className="font-serif text-xl text-blue-900 pb-4 border-b-2 py-4">
                  Event Keywords by Topic Category
                </h3>
                {event_topics.map((category) => (
                  <div key={category.id} className="mb-4">
                    <h4 className="font-semibold text-lg text-gray-700">
                      {category.category}
                    </h4>
                    <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mt-2">
                      {keywordsubtopics[category.id] &&
                        keywordsubtopics[category.id].map((subtopic, i) => (
                          <label
                            key={i}
                            className="flex items-center space-x-2"
                          >
                            <input
                              type="checkbox"
                              name="eventKeywords"
                              value={subtopic.topic}
                              className="form-checkbox text-blue-600"
                              onChange={handleCheckboxChange}
                            />
                            <span className="text-gray-800">
                              {subtopic.topic}
                            </span>
                          </label>
                        ))}
                    </div>
                  </div>
                ))}
              </div>

              <div className="">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={recaptchaSiteKey}
                  onChange={handleRecaptchaChange}
                />
              </div>

              <div className="w-full py-4">
                <Button
                  type="submit"
                  disabled={isSubmitting && !setVerified}
                  className="w-full bg-gradient-to-r from-cyan-500 via-cyan-600 to-cyan-700 text-white text-xl font-serif py-3 px-6 rounded-xl shadow-lg hover:shadow-2xl transition-all"
                >
                  Submit Event
                </Button>
              </div>
            </div>
          </div>
          {/* </div> */}
        </form>
      </div>

      <FooterComponent />
    </div>
  );
}

export default AddEvent;
