import { useState, useRef } from "react";
import { generateRandomString, dbformatCurrentDate, generatePassword } from "../utils/helperUtils";
import { postSubscribe, checkEmailExists } from "../services/api";

const useSubscribeForm = () => {
  const current_date = dbformatCurrentDate();
  const status = "New";
  const val_code = generateRandomString();
  const recaptchaRef = useRef(null);
  const password = generatePassword();
  const user_type = 'SUBSCRIBER';
  const chooseDesignation = 'Phd Student';

  const [formData, setFormData] = useState({
    reg_date: current_date,
    status: status,
    val_code: val_code,
    subscriberfullName: "",
    subscriberEmail: "",
    universityOrganization: "",
    chooseDesignation: chooseDesignation,
    subscriberCountry: "",
    subscriberState: "",
    subscriberCity: "",
    eventCategory: "",
    eventKeywords: "",
    password: password,
    user_type: user_type
  });
  
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verified, setVerified] = useState(false);

  const validateFields = () => {
    const errors = {};
    const requiredFields = [
      "subscriberfullName",
      "subscriberEmail",
      "universityOrganization",
      "chooseDesignation",
      "subscriberCountry",
      "subscriberState",
      "subscriberCity",
      "eventCategory",
      "eventKeywords",
    ];
  
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = `${field.replace(/([A-Z])/g, " $1")} is required.`;
      }
    });
  
    if (formData.subscriberEmail && !/\S+@\S+\.\S+/.test(formData.subscriberEmail)) {
      errors.subscriberEmail = "Invalid email address.";
    }
  
    return errors; // Ensure this returns the `errors` object
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const fieldErrors = validateFields(); // Now `validateFields` returns the `errors` object
    if (Object.keys(fieldErrors).length > 0) {
      setErrors((prev) => ({ ...prev, ...fieldErrors }));
      const errorMessages = Object.values(fieldErrors).join("\n");
      alert(errorMessages);
      return;
    }

    if (!verified) {
      alert("Please verify that you're not a robot!");
      return;
    }

    // Check if the email already exists in the database
    const emailExists = await checkEmailExists(formData.subscriberEmail);    
    const checkemailExists = emailExists.data.exists;

    if (checkemailExists) {
      alert("This email is already subscribed.");
      return;
    }

    setIsSubmitting(true);
    try {
      const finalData = { ...formData };
      console.log('Final data:', finalData);

      const response = await postSubscribe(finalData);
      alert(response.data.message);
      console.log("Result of fetching data",response.data);
      

      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }

      setFormData({
        reg_date: current_date,
        status: status,
        val_code: val_code,
        subscriberfullName: "",
        subscriberEmail: "",
        universityOrganization: "",
        chooseDesignation: "",
        subscriberCountry: "",
        subscriberState: "",
        subscriberCity: "",
        eventCategory: "",
        eventKeywords: "",
      });
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("An error occurred while submitting the form.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    formData,
    errors,
    isSubmitting,
    setFormData,
    handleSubmit,
    recaptchaRef,
    setVerified,
  };
};

export default useSubscribeForm;
