import React from "react";
import NavbarComponent from "../components/NavbarComponent";
import CountryForm from "../components/BannerForm";
import FooterComponent from "../components/FooterComponent";
import { Button, Label, Select } from "flowbite-react";
import FieldWithIcon from "../components/FieldWithIcon";
import { FaCircleUser } from "react-icons/fa6";
import { MdMarkEmailUnread } from "react-icons/md";
import { FaUniversity } from "react-icons/fa";
import { TbWorld, TbBuildingEstate } from "react-icons/tb";
import { PiCityFill } from "react-icons/pi";
import { BiSolidCategory } from "react-icons/bi";
import ReCAPTCHA from "react-google-recaptcha";
import useSubscribeForm from "../hooks/useSubscribeForm";
import { MdOutlineDomain } from "react-icons/md";
import { useState, useEffect } from "react";
import { getCountries, getTopics, getSubtopics } from "../services/api";

function Subscribe() {
  const {
    formData,
    setFormData,
    handleSubmit,
    recaptchaRef,
    setVerified,
    isSubmitting,
    errors,
  } = useSubscribeForm();

    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    const [subscribe_countries, setCountries] = useState([]);
    const [subscribe_topics, setTopics] = useState([]);
    const [keywordsubtopics, keywordsetSubtopics] = useState([]);
    // const [event_subtopics, setSubtopics] = useState([]);
    
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedTopic, setSelectedTopic] = useState("");
    // const [selectedSubtopic, setSelectedSubtopic] = useState("");

    useEffect(() => {
      const fetchCountries = async () => {
        try {
          const response = await getCountries();
          setCountries(response.data); // Assuming response.data contains the array of countries
        } catch (error) {
          console.error("Error fetching countries", error);
        }
      };
    
        fetchCountries(); // Call the async function
      }, []);

       useEffect(() => {
          // Fetch topics using the getTopics function
          getTopics()
            .then((response) => {
              setTopics(response.data);
      
              // Fetch subtopics for each category using getSubtopics function
              response.data.forEach((category) => {
                getSubtopics(category.id)
                  .then((res) => {
                    keywordsetSubtopics((prev) => ({
                      ...prev,
                      [category.id]: res.data.filter(
                        (subtopic) => subtopic.status === "SHOW"
                      ),
                    }));
                  })
                  .catch((err) =>
                    console.error(
                      `Error fetching subtopics for category ${category.id}:`,
                      err
                    )
                  );
              });
            })
            .catch((err) => console.error("Error fetching topics:", err));
        }, []);

    // Fetch topics when component mounts
    useEffect(() => {
      const fetchTopics = async () => {
        try {
          const response = await getTopics();
          setTopics(response.data); // Assuming topics come as an array
        } catch (error) {
          console.error("Error fetching topics", error);
        }
      };
  
      fetchTopics();
    }, []);
  
    const handleSubscriberCountryChange = (e) => {
      const value = e.target.value;
      setSelectedCountry(value);
      setFormData((prev) => ({ ...prev, subscriberCountry: value }));
    };

    const handleTopicChange = (e) => {
      const value = e.target.value;
      setSelectedTopic(value); // Update selected topic
      setFormData((prev) => ({ ...prev, eventCategory: value }));
    };

    const handleCheckboxChange = (e) => {
      const { value, checked } = e.target;
      let updatedKeywords = [];
  
      // Get the current list of keywords from the FormData (assuming it's an object)
      const currentKeywords = formData.eventKeywords || ""; // Default to an empty string if undefined
  
      // Ensure currentKeywords is treated as a string
      const keywordsArray =
        typeof currentKeywords === "string" ? currentKeywords.split("#") : [];
  
      if (checked) {
        // Add keyword if checked
        updatedKeywords = [...keywordsArray, value];
      } else {
        // Remove keyword if unchecked
        updatedKeywords = keywordsArray.filter((keyword) => keyword !== value);
      }
  
      // Update the FormData object
      const updatedFormData = {
        ...formData,
        eventKeywords: updatedKeywords.join("#"), // Convert back to a string
      };
  
      setFormData(updatedFormData); // Trigger state update for React
    };

    const handleRecaptchaChange = (token) => {
      if (token) {
        setVerified(true);
      } else {
        setVerified(false);
      }
    };

  return (
    <div>
      <NavbarComponent />
      <CountryForm />
      <div className="w-full bg-gradient-to-r from-cyan-500 via-cyan-600 to-cyan-700 text-center py-8">
        <h1 className="text-4xl font-serif text-white">Subscribe</h1>
      </div>

      <div className="container mx-auto md:p-8 p-4 md:px-20 py-8">
        <div className="bg-white rounded-2xl shadow-lg overflow-hidden p-6">
        <form className="grid grid-cols-1 gap-8" onSubmit={handleSubmit}>
          <FieldWithIcon
            icon={FaCircleUser}
            id="subscriberfullName"
            label="Full Name"
            required={true}
            value={formData.subscriberfullName}class="bg-white rounded-2xl shadow-lg overflow-hidden p-6"
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                subscriberfullName: e.target.value,
              }))
            }
          />

          <FieldWithIcon
            icon={MdMarkEmailUnread}
            id="subscriberEmail"
            label="Organizer Email"
            required={true}
            value={formData.subscriberEmail}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                subscriberEmail: e.target.value,
              }))
            }
            error={errors.subscriberEmail}
          />

          <FieldWithIcon
            icon={FaUniversity}
            id="universityOrganization"
            label="University/Organization"
            required={true}
            value={formData.universityOrganization}
            onChange={(e) =>
              setFormData((prev) => ({
                ...prev,
                universityOrganization: e.target.value,
              }))
            }
            error={errors.universityOrganization}
          />

              <div className="flex items-center gap-3">
                    <MdOutlineDomain className="text-xl text-gray-600" />
                    <Label
                      htmlFor="chooseDesignation"
                      className="text-lg text-gray-800"
                    >
                      Choose a Designation
                    </Label>
                    <span className="text-orange-400">*</span>
                  </div>
                  <Select
                    id="chooseDesignation"
                    name="chooseDesignation"
                    className="rounded-xl shadow-lg"
                    required
                    value={formData.chooseDesignation}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        chooseDesignation: e.target.value,
                      }))
                    }
                  >
                    <option>Phd Student</option>
                    <option>Master Student</option>
                    <option>Bachlor Student</option>
                    <option>Professor</option>
                    <option>Asst Professor</option>
                    <option>Other</option>
                  </Select>

              <div className="flex items-center gap-3">
                <TbWorld className="text-xl text-gray-600" />
                <Label
                  htmlFor="subscriberCountry"
                  className="text-lg text-gray-800"
                >
                  Select Country
                </Label>
                <span className="text-orange-400">*</span>
              </div>
              <Select
                id="subscriberCountry"
                value={selectedCountry}
                onChange={handleSubscriberCountryChange}
                className="rounded-xl shadow-lg"
                required
              >
                <option value="" disabled>
                  Select a country
                </option>
                {subscribe_countries.map((susbscriber_country) => (
                  <option
                    key={`${susbscriber_country.id}-${susbscriber_country.country}`}
                    value={susbscriber_country.country}
                  >
                    {susbscriber_country.country}
                  </option>
                ))}
              </Select>

              <FieldWithIcon
                    icon={TbBuildingEstate}
                    id="subscriberState"
                    label="State"
                    required={true}
                    value={formData.subscriberState}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        subscriberState: e.target.value,
                      }))
                    }
                  />

                <FieldWithIcon
                    icon={PiCityFill}
                    id="subscriberCity"
                    label="City"
                    required={true}
                    value={formData.subscriberCity}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        subscriberCity: e.target.value,
                      }))
                    }
                  />

            <div className="flex items-center gap-3">
                <BiSolidCategory className="text-xl text-gray-600" />
                <Label
                  htmlFor="eventCategory"
                  className="text-lg text-gray-800"
                >
                  Event Category
                </Label>
                <span className="text-orange-400">*</span>
              </div>
              <Select
                id="eventCategory"
                value={selectedTopic}
                onChange={handleTopicChange}
                className="rounded-xl shadow-lg"
                required
              >
                <option value="" disabled>
                  Select a topic
                </option>
                {subscribe_topics.map((event_topic, index) => (
                  <option
                    key={`${event_topic.id}-${index}`}
                    value={event_topic.id}
                  >
                    {event_topic.category}
                  </option>
                ))}
              </Select>

      {/* Event Keywords Section */}
      <div className="h-[570px] overflow-y-auto border p-4">
            <h3 className="font-serif text-xl text-blue-900 pb-4 border-b-2 py-4">
              Event Keywords by Topic Category
            </h3>
            {subscribe_topics.map((category) => (
              <div key={category.id} className="mb-4">
                <h4 className="font-semibold text-lg text-gray-700">
                  {category.category}
                </h4>
                <div className="grid md:grid-cols-3 grid-cols-1 gap-4 mt-2">
                  {keywordsubtopics[category.id] &&
                    keywordsubtopics[category.id].map((subtopic, i) => (
                      <label
                        key={i}
                        className="flex items-center space-x-2"
                      >
                        <input
                          type="checkbox"
                          name="eventKeywords"
                          value={subtopic.topic}
                          className="form-checkbox text-blue-600"
                          onChange={handleCheckboxChange}
                        />
                        <span className="text-gray-800">
                          {subtopic.topic}
                        </span>
                      </label>
                    ))}
                </div>
              </div>
            ))}
          </div>
          {/* Additional Fields with similar structure */}

            {/* ReCAPTCHA */}
            <div className="py-4">
              <ReCAPTCHA
                sitekey={recaptchaSiteKey}
                ref={recaptchaRef}
                onChange={handleRecaptchaChange}
              />
            </div>

        <div className="w-full py-4">
          <Button type="submit" className="w-full font-serif text-4xl">
            {isSubmitting ? "Submitting..." : "Subscribe"}
          </Button>
          </div>
        </form>
        </div>
      </div>

      <FooterComponent />
    </div>
  );
}

export default Subscribe;