import { useState,useRef} from "react";
import {generateRandomString,dbformatCurrentDate} from '../utils/helperUtils'
import {postEvent} from "../services/api";

const useAddEventForm = () => {

  const current_date = dbformatCurrentDate();
  const status = 'New';
  const val_code = generateRandomString();
  const org_type = "Organizer";
  const logo = 'fg';
  const event_type = 'Conference';
  const recaptchaRef = useRef(null);

  const [formData, setFormData] = useState({
    reg_date: current_date,
    status: status,
    val_code: val_code,
    logo: logo,
    org_type,
    organizerName: "",
    organizerEmail: "",
    organizationName:"",
    password: "",
    confirmPassword: "",
    eventName: "",
    eventType: event_type,
    eventCategory: "",
    eventTopic: "",
    eventCountry: "",
    eventState: "",
    eventCity: "",
    eventOrgSociety: "",
    contactPerson: "",
    eventEnqueryEmail: "",
    website: "",
    eventStartDate: "",
    eventEndDate: "",
    abstractDeadline: "",
    eventDescription: "",
    eventKeywords: "",
  });

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [verified, setVerified] = useState(false);


  const validatePasswords = () => {
    if (formData.password !== formData.confirmPassword) {
      return "Passwords do not match.";
    }
    if (formData.password.length < 6) {
      return "Password must be at least 6 characters long.";
    }
    return null;
  };

  const validateDates = () => {
    const errors = {};
    const { eventStartDate, eventEndDate, abstractDeadline } = formData;
  
    if (eventStartDate && eventEndDate && new Date(eventStartDate) >= new Date(eventEndDate)) {
      errors.eventEndDate = "Event End Date must be greater than Event Start Date.";
    }
  
    if (eventStartDate && abstractDeadline && new Date(abstractDeadline) >= new Date(eventStartDate)) {
      errors.abstractDeadline = "Abstract Deadline must be earlier than Event Start Date.";
    }
  
    return errors; // Always return an object
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const passwordError = validatePasswords();
    if (passwordError) {
      setErrors((prev) => ({ ...prev, password: passwordError }));
      alert(passwordError);
      return;
    }
  
    const dateErrors = validateDates();
    if (Object.keys(dateErrors).length > 0) {
      setErrors((prev) => ({ ...prev, ...dateErrors }));
      const errorMessages = Object.values(dateErrors).join("\n");
      alert(errorMessages);
      return;
    }

    if (!verified) {
      alert("Please verify that you're not a robot!");
      return;
    }
  
    setIsSubmitting(true);
    try {
      // Combine refs and state for final data
      const finalData = {
        ...formData,
        eventStartDate: dbformatCurrentDate(formData.eventStartDate),
        eventEndDate: dbformatCurrentDate(formData.eventEndDate),
        abstractDeadline: dbformatCurrentDate(formData.abstractDeadline),
      };
      console.log('Final data:', finalData);
  
      const response = await postEvent(finalData); // Axios will handle the response parsing
  
      // Log the response to debug
      console.log('Response object:', response);
  
      // Access the response data (as Axios automatically parses JSON)
      const result = response.data;  // The response body will be under `data` in Axios response
      console.log("Event creation result:", result);
      alert(result.message); // Show message from the backend
  
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
      // Reset form data if successful
      setFormData({
        organizerName: "",
        organizerEmail: "",
        organizationName: "",
        password: "",
        confirmPassword: "",
        eventName: "",
        eventType: "",
        eventCategory: "",
        eventTopic: "",
        eventCountry: "",
        eventState: "",
        eventCity: "",
        eventOrgSociety: "",
        contactPerson: "",
        eventEnqueryEmail: "",
        website: "",
        eventStartDate: "",
        eventEndDate: "",
        abstractDeadline: "",
        eventDescription: "",
        eventKeywords: "",
      });
      
  
    } catch (error) {
      // Handle error response
      console.error("Error submitting form:", error);
      if (error.response) {
        // If error.response exists, that means the server responded with an error
        console.error("Error response from server:", error.response);
        alert(`Error: ${error.response.data.error || 'An error occurred while submitting the form.'}`);
      } else {
        // If no error.response, then it's likely a network error or other issue
        alert(`Error: ${error.message}`);
      }
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return {
    formData,
    errors,
    isSubmitting,
    setFormData,
    handleSubmit,
    recaptchaRef,
    setVerified,
  };
};

export default useAddEventForm;
